//types
import { FilterInputConfig } from '@components/lib/module/input/filter/interfaces/FilterInput';

//library
import React, { ReactElement } from 'react';
import { Popup } from 'reactjs-popup';

/**
 * The filter input.
 *
 * @param {FilterInputConfig} config - The config
 * @returns {ReactElement} - The filter input.
 */
const FilterInput: React.FC<FilterInputConfig> = (config: FilterInputConfig): ReactElement => {
    const { fieldName, tooltip, style, options } = config.data;
    const { ToolTip } = config.lib.template.layout;

    const { handleChange, value } = config.controller.useMainCtrl(fieldName);

    return (
        <div className="flex flex-wrap">
            {options.map((option) => {
                return tooltip ? (
                    <div key={option.value}>
                        <ToolTip data={{ content: option.label }} lib={{ external: { Popup } }}>
                            <>
                                <input
                                    type="checkbox"
                                    id={`compact-filter-${option.label}`}
                                    className="hidden"
                                    onChange={() => handleChange(option.value)}
                                />
                                <label
                                    htmlFor={`compact-filter-${option.label}`}
                                    aria-label={`compact-filter-${option.label}`}
                                    className={`${
                                        value.includes(option.value) && `${option.selectedColor}`
                                    } ${!value.includes(option.value) && option.textColor} ${
                                        style ||
                                        'w-7 h-7 rounded-full mr-3 font-bold 2xl:hidden cursor-pointer flex items-center justify-center'
                                    }`}>
                                    {option.label[0]}
                                </label>
                            </>
                        </ToolTip>
                        <input
                            aria-label={`filter-input-${option.label}-large`}
                            type="checkbox"
                            id={`compact-filter-${option.label}-large`}
                            className="hidden"
                            onChange={() => handleChange(option.value)}
                        />
                        <label
                            htmlFor={`compact-filter-${option.label}-large`}
                            aria-label={`compact-filter-${option.label}-large`}
                            className={`${
                                value.includes(option.value) && `${option.selectedColor}`
                            } ${
                                !value.includes(option.value) && option.textColor
                            } px-3 py-2 rounded-3xl mr-2 font-bold hidden 2xl:block cursor-pointer`}>
                            {option.label}
                        </label>
                    </div>
                ) : (
                    <div key={option.value}>
                        <input
                            type="checkbox"
                            id={`filter-${option.label}`}
                            className="hidden"
                            onChange={() => handleChange(option.value)}
                        />
                        <label
                            htmlFor={`filter-${option.label}`}
                            aria-label={`filter-${option.label}`}
                            className={`${
                                value.includes(option.value) && `${option.selectedColor}`
                            } ${!value.includes(option.value) && option.textColor} ${
                                style ||
                                'px-2 md:px-3 py-1 md:py-2 rounded-3xl mr-3.5 mt-1.5 sm:mt-0 font-bold cursor-pointer'
                            }`}>
                            {option.label}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default FilterInput;
