//types
import { FilterInputProps } from '@components/lib/module/input/filter/interfaces/FilterInput';
import {
    UseControllersReturn,
    FormFields,
    UseMainCtrlReturn
} from '@components/lib/module/input/filter/interfaces/app/controllers/useControllers';

/**
 * The controller.
 *
 * @param {FilterInputProps} props - The props
 * @returns {UseControllersReturn} - The controllers.
 */
const useControllers = ({
    lib: {
        external: { Formik }
    }
}: FilterInputProps): UseControllersReturn => {
    /**
     * The main controller function
     *
     * @param {string} fieldName - The fieldName from the form
     * @returns {UseMainCtrlReturn} - The main function
     */
    const useMainCtrl = (fieldName: string): UseMainCtrlReturn => {
        const { values, setFieldValue, submitForm } = Formik.useFormikContext<FormFields>();

        /**
         * The handleChange function for filter inputs in toolbar forms
         *
         * @param {string} selected - The input value
         */
        const handleChange = (selected: string) => {
            const input = [...values[fieldName]];

            const index = values[fieldName].indexOf(selected);

            if (index == -1) {
                input.push(selected);
            } else {
                input.splice(index, 1);
            }

            setFieldValue(fieldName, input);

            submitForm();
        };

        return { handleChange, value: values[fieldName] };
    };

    return { useMainCtrl };
};

export default useControllers;
