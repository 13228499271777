//types
import { DateProps, DateConfig } from '@components/lib/module/input/date/interfaces/Date';

//library
import React, { ReactElement } from 'react';

//controller
import useControllers from '@components/lib/module/input/date/app/controllers/useControllers';

//model
import dateInputModel from '@components/lib/module/input/date/app/model/dateInputModel';

//code
import Main from '@components/lib/module/input/date/app/view/Main';

/**
 * The date input.
 *
 * @param {DateProps} props - The props
 * @returns {ReactElement} - The date input.
 */
const DateInput: React.FC<DateProps> = (props: DateProps): ReactElement => {
    const config: DateConfig = {
        ...props,
        controller: useControllers({
            ...props,
            model: { ...props, dateInputModel: dateInputModel() }
        })
    };

    return <Main {...config} />;
};

export default DateInput;
