//type
import { ToolTipProps } from '@components/lib/interfaces/template/layout/ToolTip';

//library
import React, { ReactElement } from 'react';

/**
 * The offer information pop-up.
 *
 * @param {ToolTipProps} props - The props
 * @returns {React.ReactElement} - The pop-up.
 */
const ToolTip: React.FC<ToolTipProps> = ({
    data: { content },
    children,
    lib: {
        external: { Popup }
    }
}: ToolTipProps): ReactElement => (
    <Popup
        aria-label="tooltip"
        trigger={() => <span>{children}</span>}
        position={['bottom right', 'bottom left', 'bottom center']}
        closeOnDocumentClick
        keepTooltipInside=".tooltipBoundary"
        on="hover"
        contentStyle={{
            width: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 3px',
            background: 'rgb(255, 255, 255)',
            padding: '0.375rem',
            fontSize: '0.75rem',
            lineHeight: '1rem'
        }}>
        {content && <span aria-label="tooltip-text">{content}</span>}
    </Popup>
);

export default ToolTip;
