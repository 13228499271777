//types
import {
    State,
    Action
} from '@components/lib/module/input/date/interfaces/app/controllers/useControllers';

export const initialState: State = {
    showCalendar: false,
    edit: false
};

/**
 * The reducer function to control state
 *
 * @param {State} state - The state.
 * @param {Action} action - The action and data.
 * @returns {State} - The updated state.
 */
export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SHOW':
            return {
                ...state,
                showCalendar: true
            };
        case 'HIDE':
            return {
                ...state,
                showCalendar: false
            };
        case 'EDIT':
            return {
                ...state,
                edit: true
            };
        default:
            return {
                ...state
            };
    }
};
