//types
import { DateInputModelsReturn } from '@components/lib/module/input/date/interfaces/app/model/dateInputModel';

/**
 * Date Input Model
 *
 * @returns {DateInputModelsReturn} - The model functions.
 */
const dateInputModel = (): DateInputModelsReturn => {
    /**
     * Checks if string is in DD/MM/YYYY format and a valid date
     *
     * @param {string} str - The string to check.
     * @returns {boolean | Date} True if string is in DD/MM/YYYY format and is valid.
     */
    const isFormattedDate = (str: string): boolean | Date => {
        //check format is correct
        if (!/^(?=.{10,10}$)\d{2}\/\d{2}\/\d{4}/.test(str) || str.length !== 10) {
            return false;
        }

        //check that date is valid
        const [day, month, year] = str.split('/');
        const date = new Date(month + '/' + day + '/' + year);
        if (isNaN(Number(date))) {
            return false;
        }

        return date;
    };

    /**
     * Checks if string is in ISO date format and is valid.
     *
     * @param {string} str - The string ot check
     * @returns {boolean} True if string is in ISO date format and is valid.
     */
    const isIsoDate = (str: string): boolean => {
        const ISO_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

        //check format is correct
        if (!ISO_REGEX.test(str) || str.length !== 24) {
            return false;
        }

        const date = new Date(str);
        return !isNaN(Number(date)) && date.toISOString() === str;
    };

    /**
     * Convert date to dd/mm/yyyy format
     *
     * @param {string} value - Date value
     * @returns {string} - Formatted Date.
     */
    const getFormattedDate = (value: string | Date): string => {
        if (!value) {
            return '';
        }

        const date = new Date(value as Date);

        const actualMonth = date.getMonth() + 1;

        // Put 0 in front of day or month if it's only 1 digit
        const formattedDay =
            date.getDate().toString().length === 1 ? `0${date.getDate()}` : `${date.getDate()}`;
        const formattedMonth =
            actualMonth.toString().length === 1 ? `0${actualMonth}` : `${actualMonth}`;

        return `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
    };

    /**
     * Get ISO date value
     *
     * @param {string} value - Date value
     * @returns {string} - Date in ISO format.
     */
    const getIsoDate = (value: Date): string => {
        const date = value;
        date.setHours(16, 0, 0, 0);

        return date.toISOString();
    };

    return { isFormattedDate, isIsoDate, getFormattedDate, getIsoDate };
};

export default dateInputModel;
