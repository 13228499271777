//type
import { FormatDateProps } from '@components/lib/interfaces/template/helper/FormatDate';

//library
import React, { ReactElement } from 'react';

/**
 * Format the date into dd/mm/yyyy, hh:mm
 *
 * @param {FormatDateProps} props - The date to be formatted
 * @returns {string} - The formatted date.
 */
const FormatDate: React.FC<FormatDateProps> = ({
    data: { date, displayTime = true }
}: FormatDateProps): ReactElement => (
    <>
        {new Date(date).toLocaleString('en-GB', {
            dateStyle: 'medium',
            timeStyle: displayTime ? 'short' : undefined
        })}
    </>
);

export default FormatDate;
