//types
import {
    FilterInputProps,
    FilterInputConfig
} from '@components/lib/module/input/filter/interfaces/FilterInput';

//library
import React, { ReactElement } from 'react';

//controller
import useControllers from '@components/lib/module/input/filter/app/controllers/useControllers';

//codes
import Main from '@components/lib/module/input/filter/app/view/Main';

/**
 * The filter input
 *
 * @param {FilterInputProps} props - The props
 * @returns {ReactElement} - The filter input.
 */
const FilterInput: React.FC<FilterInputProps> = (props: FilterInputProps): ReactElement => {
    const config: FilterInputConfig = {
        ...props,
        controller: useControllers(props)
    };

    return <Main {...config} />;
};

export default FilterInput;
