//types
import { ParsedUrlQueryInput } from 'querystring';

/**
 * Function to update the url query
 *
 * @param {ParsedUrlQueryInput} defaultQuery - The query.
 * @param {ParsedUrlQueryInput} query - The query.
 * @returns {ParsedUrlQueryInput} Query - The new query.
 */
const updateUrlRsl = (
    defaultQuery: ParsedUrlQueryInput,
    query?: ParsedUrlQueryInput
): ParsedUrlQueryInput => {
    const data: ParsedUrlQueryInput = !query ? { ...defaultQuery } : { ...query };

    const filteredQuery = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(data).filter(([k, v]) => Boolean(v))
    );

    return filteredQuery;
};

export default updateUrlRsl;
