//library
import React, { useReducer } from 'react';

//types
import {
    UseControllersProps,
    UseControllersReturn,
    UseMainCtrlReturn
} from '@components/lib/module/input/date/interfaces/app/controllers/useControllers';

//store
import * as store from '@components/lib/module/input/date/app/controllers/store';

/**
 * The controller.
 *
 * @param {UseControllersProps} props - The props
 * @returns {UseControllersReturn} - The controllers.
 */
const useControllers = ({
    data: { showText },
    lib: {
        external: { Formik }
    },
    model: { dateInputModel }
}: UseControllersProps): UseControllersReturn => {
    const [state, dispatch] = useReducer(store.reducer, store.initialState);

    /**
     * The main controller function
     *
     * @param {string} field - The fieldName from the form
     * @returns {UseMainCtrlReturn} - The main functions and state
     */
    const useMainCtrl = (field: string): UseMainCtrlReturn => {
        const [, meta, helpers] = Formik.useField({ name: field });
        const { value, error } = meta;
        const { setValue, setTouched } = helpers;

        const date = value;

        const hideInput = date && showText && !state.edit;

        /** Show the react calendar function */
        const showCalendar = () => {
            dispatch({ type: 'SHOW' });
        };

        /** Hide the react calendar function */
        const hideCalendar = () => {
            dispatch({ type: 'HIDE' });
        };

        /** Clear the input value in the form */
        const resetValue = () => {
            setValue('');
        };

        /**
         * The handle change function that will be called on input element in the form
         *
         * @param {React.ChangeEvent<HTMLInputElement>} event - The change event on HTML input element
         */
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;
            const date = dateInputModel.isFormattedDate(value);

            //if user have entered a valid date string, converts and saves the value in ISO format
            if (date) {
                value = dateInputModel.getIsoDate(new Date(Number(date)));
            }

            setValue(value);
            setTouched(true);
        };

        /**
         * The handle change function for react calendar in the form
         *
         * @param {Date} date - The chosen date
         */
        const handleCalendarChange = (date: Date) => {
            const value = dateInputModel.getIsoDate(date);

            setValue(value);
            dispatch({ type: 'EDIT' });

            hideCalendar();
        };

        /** Show the date input */
        const showInput = () => {
            dispatch({ type: 'EDIT' });
        };

        return {
            showCalendar,
            hideCalendar,
            resetValue,
            handleChange,
            handleCalendarChange,
            state,
            date,
            error,
            hideInput,
            showInput,
            isIsoDate: dateInputModel.isIsoDate,
            getFormattedDate: dateInputModel.getFormattedDate
        };
    };

    return { useMainCtrl };
};

export default useControllers;
