//type
import { DateConfig } from '@components/lib/module/input/date/interfaces/Date';

//library
import React, { ReactElement } from 'react';
import { Calendar } from 'react-date-range';

/**
 * Date input
 *
 * @param {DateConfig} config - The config
 * @returns {ReactElement} - Date input.
 */
const DateInput: React.FC<DateConfig> = (config: DateConfig): ReactElement => {
    const {
        template: {
            helper: { FormatDate }
        }
    } = config.lib;

    const { field, isCompact, showError = true, isClearable = true } = config.data;

    const {
        showCalendar,
        hideCalendar,
        resetValue,
        handleChange,
        handleCalendarChange,
        state,
        date,
        error,
        isIsoDate,
        getFormattedDate,
        hideInput,
        showInput
    } = config.controller.useMainCtrl(field);

    return (
        <div className="relative">
            <div
                aria-label="calendar-input"
                className="w-full flex items-center justify-end appearance-none">
                <input
                    type="text"
                    aria-label={config.data.field}
                    value={isIsoDate(date) ? getFormattedDate(date) : date || ''}
                    placeholder="YYYY-MM-DD"
                    onChange={handleChange}
                    onFocus={hideCalendar}
                    className={`${
                        hideInput ? 'hidden' : 'inline-block'
                    } w-full text-right focus:outline-none focus:shadow-outline border-b border-opacity-0 focus:border-opacity-100 hover:border-opacity-100 border-black transition duration-150 ease-in-out`}
                />
                {hideInput && (
                    <button aria-label="date-text" onClick={showInput} type="button">
                        <FormatDate data={{ date, displayTime: false }} />
                    </button>
                )}
                <div className={`flex items-center ${hideInput ? 'hidden' : 'inline-block'}`}>
                    {date && isClearable && (
                        <button
                            aria-label="clear-date"
                            type="button"
                            onClick={resetValue}
                            className="ml-1">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle
                                    opacity="0.5"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="#29618D"
                                    strokeWidth="1.5"
                                />
                                <path
                                    d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
                                    stroke="#29618D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>
                    )}
                    <svg
                        className="w-4 h-4 cursor-pointer ml-2 mr-1"
                        viewBox="0 0 56 60"
                        aria-label={config.data.field + '-calendar-icon'}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={state.showCalendar ? hideCalendar : showCalendar}>
                        <title>calendar icon</title>
                        <path
                            d="M55 4H48V1C48 0.447 47.553 0 47 0H40C39.447 0 39 0.447 39 1V4H17V1C17 0.447 16.553 0 16 0H9C8.447 0 8 0.447 8 1V4H1C0.447 4 0 4.447 0 5V16V59C0 59.553 0.447 60 1 60H55C55.553 60 56 59.553 56 59V16V5C56 4.447 55.553 4 55 4ZM41 2H46V5V8H41V5V2ZM10 2H15V5V8H10V5V2ZM2 6H8V9C8 9.553 8.447 10 9 10H16C16.553 10 17 9.553 17 9V6H39V9C39 9.553 39.447 10 40 10H47C47.553 10 48 9.553 48 9V6H54V15H2V6ZM2 58V17H54V58H2Z"
                            fill="black"
                        />
                        <path
                            d="M36 23H29H27H20H18H9V32V34V41V43V52H18H20H27H29H36H38H47V43V41V34V32V23H38H36ZM29 25H36V32H29V25ZM36 41H29V34H36V41ZM20 34H27V41H20V34ZM20 25H27V32H20V25ZM11 25H18V32H11V25ZM11 34H18V41H11V34ZM18 50H11V43H18V50ZM27 50H20V43H27V50ZM36 50H29V43H36V50ZM45 50H38V43H45V50ZM45 41H38V34H45V41ZM45 25V32H38V25H45Z"
                            fill="black"
                        />
                    </svg>
                </div>
            </div>

            {state.showCalendar && (
                <div
                    aria-label="calendar-div"
                    className={`${isCompact ? 'absolute top-3 right-0 z-[100]' : ''}`}>
                    {!isCompact && (
                        <div className="relative z-10 text-saigray-text text-sm leading-5 mt-5 -mb-5">
                            Choose a date.
                        </div>
                    )}
                    <Calendar
                        date={isIsoDate(date) ? new Date(date) : undefined}
                        onChange={(date) => handleCalendarChange(date)}
                        editableDateInputs={true}
                    />
                </div>
            )}
            {showError && error && (
                <div aria-label="date-error" className="text-red-500 text-right text-xxs italic">
                    {error}
                </div>
            )}
        </div>
    );
};

export default DateInput;
